<template>

  <div v-if="$store.state.business.selectedBusiness && $store.state.business.selectedBusiness.alias" class="d-flex flex-column">
    <v-sheet  class="flex-grow-1">
      <default-site-header />
      <webinar-public-default
          v-if="!!webinar"
          :webinar-uuid="webinar"
      />
      <default-site-home v-else-if="!course"  />
      <default-course
          v-else-if="course"
          :courseUuid="course"
      />
      <default-site-news v-else-if="false" />



    </v-sheet>
    <default-site-footer />
  </div>

  <v-sheet class="fill-height d-flex align-center justify-center" v-else>
    <v-sheet  width="1240" class="px-4" >
      <v-row>
        <v-col :cols="!SM ? 6 : 12">
          <div class="fill-height d-flex align-center">
            <div>
              <img class="text-center pointer  mt-2"
                   height="32"
                   width="auto"
                   alt="WeStudy"
                   src="/library/img/westudy_logo.png"
              />
              <h1 :style="`color : ${wsDARKER}`" class="mt-4">{{ $t('homepage.school_not_found.title') }}</h1>
              <h3 :style="`color : ${wsDARKER}`" class="font-weight-regular">{{ $t('homepage.school_not_found.text') }}</h3>
              <ws-button class="mt-3" :to="localeLink('')" label="homepage.school_not_found.goto_home"></ws-button>
            </div>
          </div>

        </v-col>
        <v-col v-if="!SM"  cols="6">
          <div class="d-flex justify-end">
            <v-img max-width="400" src="@/assets/img/avalon/404.png"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

  </v-sheet>
</template>

<script>
import defaultConfig from '@/assets/json/avalon/defaultConfig.json'

import DefaultSiteHome from "@/components/AvalonEditor/defaultSite/DefaultSiteHome";
// import DefaultSiteCourse from "@/components/AvalonEditor/defaultSite/DefaultSiteCourse";
import DefaultCourse from "@/components/AvalonEditor/defaultSite/Course/DefaultCourse.vue";
import DefaultSiteNews from "@/components/AvalonEditor/defaultSite/DefaultSiteNews";
import defaultSiteHeader from "@/components/AvalonEditor/defaultSite/UI/defaultSiteHeader";
import defaultSiteFooter from "@/components/AvalonEditor/defaultSite/UI/defaultSiteFooter";

import webinarPublicDefault from "@modules/webinars/components/Webinar/WebinarPublicDefault.vue";

export default {
  name: "DefaultSite",
  components : {
    DefaultSiteHome,
    DefaultCourse,
    DefaultSiteNews,
    defaultSiteHeader,
    defaultSiteFooter,
    webinarPublicDefault
  },
  props : {
    alias : {
      type : String
    },
    pageAlias : {
      type : String
    },
    newsAlias : {
      type : String
    },
    course : {
      type : String
    },
    webinar : {
      type : String
    },
  },
  watch : {
    course() {
      window.scrollTo(0,0)
    },
  },
  beforeMount() {
    this.$store.state.avalon.config = this.COPY(defaultConfig)
    this.$store.state.avalon.defaultConfig = this.COPY(defaultConfig)
    this.LOAD_FONTS()
  }
}
</script>

<style scoped>

</style>